
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				#ff0000;
@link-hover-color:			#000;
@link-hover-decoration:		underline;

@font-family-base:			Verdana, Arial, Helvetica, sans-serif;
@font-size-base:			10px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				488px;
@thumb-height:				366px;
@thumb-margin-top:			0;
@thumb-margin-right:		1px;
@thumb-margin-bottom:		0;
@thumb-margin-left:			1px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #000;
@thumb-hover-border:		@thumb-border-width solid #fff;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				18px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				18px;
@sites-list-title-weight:			normal;
@sites-list-title-decoration:		none;

@sites-footer-traffictrade-size:	12px;
@sites-footer-traffictrade-margin:	0 0 10px;
@sites-footer-margin:				20px 2px 2px;
@sites-footer-size:					12px;
@sites-footer-color:				#000;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/sites_footer.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
h1 {
	text-align: center;
	font-weight: bold;
	a {
		color: #000;
		text-decoration: none;
	}
	small {
		font-weight: normal;
		font-size: 18px;
		color: #000;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
.thumb-simple {
	border-bottom: 1px solid #FB0000;
	height: @thumb-height + @thumb-margin-top + @thumb-margin-bottom + (2 * @thumb-border-width) + 1;
}